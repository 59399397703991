import {
  ETHEREUM_ADDRESS_REGEX,
  isValidEmail,
  normalizeUrl,
} from "@luma-team/shared";
import CheckIcon from "@lux/icons/feather/check.svg";
import XIcon from "@lux/icons/feather/x.svg";
import classNames from "classnames";
import { Field, FieldProps, getIn, useFormikContext } from "formik";
import React, {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

import { LuxInputProps, LuxInputWrapper } from "./_LuxInputWrapper";
import { Spinner } from "./Spinner";
import { isValidUrl } from "../utils/tlds";

export type LuxInputRef = {
  focus: () => void;
};

export const LuxInput = forwardRef(
  (
    {
      label,
      placeholder,
      id,
      className,
      value,
      onChange,
      size = "medium",
      type = "text",
      disabled,
      rounded,
      autoFocus,
      variant = "outline",
      monospace = false,
      textAlign = "left",
      statusIndicator,
      error,
      helperText,
      errorText,
      accessoryText,
      accessoryTextPlacement,
      suppress1Password,
      inputProps,
    }: LuxInputProps & {
      placeholder?: string;
      id?: string;
      value: string;
      onChange: (value: string) => void;
      type?:
        | "email"
        | "eth_address"
        | "name"
        | "password"
        | "text"
        | "title"
        | "token"
        | "url"

        // We don't allow "number" because we want to be explicit about
        // if we are entering a whole number or a decimal value. Safari
        // on iOS does not have nice behavior for decimal inputs so we use
        // a text input there.
        | "numeric"
        | "decimal";
      disabled?: boolean;
      autoFocus?: boolean;
      monospace?: boolean;
      textAlign?: "left" | "right";
      statusIndicator?: "loading" | "success" | "error";
      errorText?: string;
      accessoryText?: React.ReactNode;
      accessoryTextPlacement?: "left" | "right";
      suppress1Password?: boolean;
      inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
    },
    ref: ForwardedRef<LuxInputRef>,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (autoFocus) {
        inputRef.current?.focus();
      }
    }, [autoFocus]);

    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
    }));

    let props: InputHTMLAttributes<HTMLInputElement>;

    if (type === "name") {
      props = {
        type: "text",
        autoCapitalize: "words",
        autoCorrect: "off",
        spellCheck: "false",
      };
    } else if (type === "url") {
      props = {
        type: "url",
        autoCapitalize: "off",
        autoCorrect: "off",
        spellCheck: "false",
      };
    } else if (type === "title") {
      props = {
        type: "text",
        autoCapitalize: "sentences",
      };
    } else if (type === "token" || type === "eth_address") {
      props = {
        type: "text",
        autoCorrect: "off",
        spellCheck: "false",
      };
    } else if (type === "numeric") {
      props = {
        type: "number",
        inputMode: "numeric",
      };
    } else if (type === "decimal") {
      props = {
        type: "text",
        inputMode: "decimal",
      };
    } else {
      props = { type };
    }

    if (suppress1Password) {
      // @ts-ignore
      props["data-1p-ignore"] = true;
    }

    const helperTextToDisplay = error && errorText ? errorText : helperText;

    return (
      <LuxInputWrapper
        label={label}
        className={className}
        size={size}
        rounded={rounded}
        variant={variant}
        error={error}
        helperText={helperTextToDisplay}
        accessoryText={accessoryText}
        accessoryTextPlacement={accessoryTextPlacement}
      >
        <div className={classNames("input-inner-wrapper flex-1", size)}>
          <input
            id={id}
            ref={inputRef}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={(e) => {
              // Relative URLs like google.com aren't valid for type=url.
              // On blur, we check if the URL is a valid one, normalize it (make
              // it absolute), and if it's different from the user input (i.e.
              // the URL is relative), we change it to absolute.
              if (type === "url" && e.target.value) {
                const normalized = normalizeUrl(e.target.value);
                if (normalized && normalized !== value) {
                  onChange(normalized);
                }
              }
            }}
            {...(inputProps || {})}
            className={classNames("luma-input", inputProps?.className, {
              monospace,
              "align-right": textAlign === "right",
              "has-indicator": statusIndicator,
            })}
            {...props}
          />

          <div
            className={classNames("indicator", statusIndicator, {
              // We don't unmount this so that we can more easily animate the opacity
              // to fade the status indicator in + out
              invisible: !statusIndicator,
            })}
          >
            {statusIndicator === "loading" && <Spinner />}
            {statusIndicator === "success" && <CheckIcon />}
            {statusIndicator === "error" && <XIcon />}
          </div>
        </div>
      </LuxInputWrapper>
    );
  },
);

export const LuxInputField = forwardRef(
  (
    {
      name,
      label,
      className,
      helperText,
      type = "text",
      size = "medium",
      placeholder,
      autoFocus,
      monospace,
      textAlign,
      disabled,
      required,
      accessoryText,
      accessoryTextPlacement = "left",
      statusIndicator,
      inputProps,
      setValueTransformer,
      onChange,
      displayValueTransformer,
      rounded,
      validate,
      suppress1Password,
      id,
      ...props
    }: {
      name: string;
      required?: boolean;
      setValueTransformer?: (value: string) => any;
      displayValueTransformer?: (value: any) => string;
      validate?: (
        value: string | null,
      ) => Promise<string | undefined> | string | undefined;
    } & Omit<
      React.ComponentPropsWithoutRef<typeof LuxInput>,
      "onChange" | "value" | "error"
    > &
      Partial<
        Pick<
          React.ComponentPropsWithoutRef<typeof LuxInput>,
          "onChange" | "value" | "error"
        >
      >,
    ref: ForwardedRef<LuxInputRef>,
  ) => {
    const { values, setFieldValue, isSubmitting, submitCount, validateField } =
      useFormikContext();

    return (
      <Field
        name={name}
        validate={
          validate
            ? validate
            : (value: string | null) => {
                if ((value == null || value === "") && required) {
                  return "This field is required.";
                }

                if (value == null) {
                  return undefined;
                }

                if (type === "url") {
                  if (!value && !required) {
                    return undefined;
                  }

                  if (!isValidUrl(value)) {
                    return "Please enter a valid url.";
                  }

                  return undefined;
                }

                if (type === "email" && !isValidEmail(value)) {
                  return "Please enter a valid email address.";
                }

                if (
                  type === "eth_address" &&
                  !ETHEREUM_ADDRESS_REGEX.test(value)
                ) {
                  return `Please enter a valid Ethereum address.`;
                }

                return undefined;
              }
        }
      >
        {({ meta, field }: FieldProps<string>) => {
          const changed = Boolean(
            field.value !== meta.initialValue && meta.touched,
          );
          const showError = Boolean(meta.error && (submitCount || changed));
          return (
            <LuxInput
              ref={ref}
              {...props}
              id={id}
              label={label}
              className={className}
              type={type}
              size={size}
              placeholder={placeholder}
              monospace={monospace}
              textAlign={textAlign}
              accessoryText={accessoryText}
              accessoryTextPlacement={accessoryTextPlacement}
              statusIndicator={statusIndicator}
              disabled={disabled || isSubmitting}
              autoFocus={autoFocus}
              error={showError}
              helperText={showError ? meta.error : helperText}
              value={(() => {
                let value = getIn(values, name) || "";
                if (displayValueTransformer) {
                  value = displayValueTransformer(value);
                }
                return value;
              })()}
              onChange={
                onChange ??
                ((value: string) => {
                  if (setValueTransformer) {
                    value = setValueTransformer(value);
                  }
                  setFieldValue(name, value);
                })
              }
              inputProps={{
                name: name,
                onBlur: (e) => {
                  const value = getIn(values, name) || "";

                  if (type === "url" && value) {
                    const isAbsoluteUrl = value
                      .toLowerCase()
                      .startsWith("http");
                    if (!isAbsoluteUrl) {
                      setFieldValue(name, "https://" + value);
                      setTimeout(() => validateField(name), 1);
                    }
                  }

                  field.onBlur(e);
                },
                ...inputProps,
              }}
              rounded={rounded}
              suppress1Password={suppress1Password}
            />
          );
        }}
      </Field>
    );
  },
);

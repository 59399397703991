import Linkify from "linkify-react";
import punycode from "punycode";
import React from "react";

export const LinkedText = ({
  text,
  className,
  tag,
  onClick,
}: {
  text: string;
  className?: string;
  tag?: keyof JSX.IntrinsicElements;
  onClick?: () => unknown;
}) => {
  return (
    <Linkify
      as={tag || "div"}
      options={{
        className,
        events: onClick ? { click: onClick } : undefined,
        format: (value, type) => {
          if (type === "url") {
            value = punycode.toASCII(value);
            if (value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
          }
          return value;
        },
      }}
    >
      {text}
    </Linkify>
  );
};
